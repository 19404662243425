import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../../components/menus/basicmenubcmenu';
import CVNavbar from '../../components/menus/cviruspagemenu';
import InlineBodyTransform from '../../components/inlinebodytransform';
import Layout from '../../components/layout';
import Navbar from '../../components/menus/basicpagemenu';
import React from 'react';
import SEO from '../../components/seo';
import { graphql } from 'gatsby';

const Detail = ({ data }) => {

  //  Page
  const post = data.nodePage
  let backgroundImage = ''
  if (post.relationships.field_image != null) {
    backgroundImage = post.relationships.field_image.localFile.childImageSharp.original.src
    backgroundImage = encodeURI(backgroundImage)
  }

  /**
   * Check if it is CV page
   */
  let cvCheck = false
  if (post.path.alias) {
    if (post.path.alias == "/coronavirus-updates") {
      cvCheck = true
    }
  }

  return (
    <Layout>
      <SEO 
        title={post.title}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <Navbar />
              {(cvCheck)?<CVNavbar />:null}
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight editCont">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${backgroundImage})`}}>
                {post.title}
              </h1>
              <BreadcrumbMenu title={post.title}/>
              <InlineBodyTransform bodyValue={post.body} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
  query($id: String!) {
    nodePage(id: { eq: $id }) {
      title
      body {
        value
      }
      path {
        alias
      }
      created
      relationships {
        field_image {
          localFile{
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`