import { MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';

import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const MDBCardCompMenu = styled(MDBCard)`
    width:100%;
    margin: 0px;
    padding:0px;
    position: relative;
    left: 50%;
    top: 14px!important;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
`

const MDBCardBodyComp = styled(MDBCardBody)`
    text-align: left;
    @media only screen and (max-width: 767px) {
        padding-top: 0px;
    }
    ul{
        width:100%;
        margin: 0px;
        padding: 0px;
        list-style-position: outside;
        list-style: none;
        li {
            width:100%;
            background: none;
            border-bottom: 1px #ccc solid;
            padding: 0em 0.1em;
            margin: 0 auto;
            display: inline-block;
            a{
                color: #575549;
                font-size: 0.8em;
            }
            li {
                padding: 0em 0.4em;
                border-bottom: none;
            }
        }
    }
`

function Rightmenu() {

    return (
        <>
            <div className="d-none d-md-inline m-0 p-0">
                <Link 
                    to="/images/updates/files/ghc_poc_covid_lab_testing_price_list.xlsx" 
                    className="red-text-Genesis text-center"
                >
                    <MDBCardCompMenu className="my-2">
                        <MDBCardBodyComp className="text-center m-0 p-2"> 
                            <MDBIcon icon="fas fa-child" size="3x" className="black-text-Genesis m-0 p-0" />
                            <h5 className="font-weight-bold my-3 red-text-Genesis gray-shadow">
                                CARES Act Section 3202(b) Notice of COVID-19 Cash Prices
                            </h5>
                            <p className="gray-text-Genesis justify my-2">
                                The Coronavirus Aid, Relief, and Economic Security Act (CARES) includes a
                                number of provisions to provide relief to the public from issues caused by the
                                pandemic, including price transparency for COVID -19 testing. Section 3202(b) of
                                the CARES Act requires providers of diagnostic tests for COVID-19 to post the cash
                                price for a COVID-19 diagnostic test on their website. These cash prices are valid
                                during the COVID-19 Public Health Emergency only. <u>Click here</u> to find the cash price as used in the
                                CARES Act COVID-19 diagnostic antigen tests for individuals who seek COVID-19
                                testing from the Center.
                            </p>
                        </MDBCardBodyComp>
                    </MDBCardCompMenu>
                </Link>
            </div>
        </>
    )
}


export default Rightmenu;